import api from '@/libs/axios'

export default {
  async recalculateRecords(payload) {
    const response = await api.post('/admin/marnet-employee-records/recalculate', payload)
    return response || null
  },
  async update(payload) {
    let response = await api.put('/admin/marnet-employee-records', payload)
    return response
  },
  async delete(payload) {
    const response = await api.delete('/admin/marnet-employee-records', {
      data: payload,
    })
    return response
  },
  async getList(params) {
    let response = await api.get('/admin/marnet-employee-records', {
      params,
    })
    return response
  },
  async get(params) {
    let response = await api.get('/admin/marnet-employee-records/detail', {
      params,
    })
    return response.data.data.document || null
  },
}

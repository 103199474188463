<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Marnet Employee" class="mb-3" />
      </b-col>
    </b-row>
    <h2 class="mb-2">Activity Report</h2>
    <loading-button variant="success" class="mb-1" icon="RefreshCcwIcon" :is-loading="is_recalculating"
      @click="recalculateReports"
    >Recalculate</loading-button>

    <div class="d-flex align-items-end">
      <date-picker v-model="time_range" is-range style="z-index: 1000; margin-bottom: -3px;" mode="date">
        <template v-slot="{ inputValue, inputEvents }">
          <label>Filter Date</label>
          <div class="d-flex justify-content-center align-items-center w-auto">
            <b-form-input
              :value="inputValue.start ? $options.filters.local_date(inputValue.start) : ''"
              v-on="inputEvents.start"
            />
            <svg style="width: 3em; height: 3em;"
              class="mx-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
            <b-form-input
              :value="inputValue.end ? $options.filters.local_date(inputValue.end) : ''"
              v-on="inputEvents.end"
            />
          </div>
        </template>
      </date-picker>
      <b-form-input class="mx-3 w-25" label="Search by email"
        v-model="search_email"
        id="email"
        type="text"
        placeholder="Search by email"
      />
      <loading-button variant="warning" class="ml-auto" icon="FileTextIcon" :is-loading="is_exporting"
        @click="getResults(true)"
      >Export</loading-button>
    </div>
    <section class="py-3">
      <b-table :items="display_results" :fields="fields" responsive="sm">
        <template #cell(stt)="data">
          {{ data.index + 1 }}
        </template>
      </b-table>
    </section>

    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>

<script>
import service from "../service"
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import XLSX from 'xlsx'
import _ from 'lodash'

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      current_page: 1,
      total_items: 0,
      items_perpage: 30,
      display_results: [],
      export_results: [],
      is_exporting: false,
      editLanguage: null,
      time_range: {
        start: null,
        end: null,
      },
      query: {
      },
      search_email: null,
      fields: ["day", "email","arena_learn", "arena_challenge", "vocab_topic", "pronun_topic", "listening_topic", "total"],
      is_recalculating: false,
    };
  },
  watch: {
    current_page() {
      this.getResults();
    },
    'time_range.end'() {
      this.getResults()
    },
    'search_email'() {
      this.getResults()
    },
  },
  created() {
    this.getResults();
  },
  methods: {
    async getResults(is_export = false) {
      _.debounce(async () => {
        if(is_export) { this.is_exporting = true }
        let query = {
          ...this.query,
        };
        let response = await service.getList({
          query: encodeURIComponent(JSON.stringify(query)),
          time_from: this.time_range.start,
          time_to: this.time_range.end,
          page: this.is_exporting ? 1 : this.current_page,
          limit: this.is_exporting ? 9000 : this.items_perpage,
          search_key: this.search_email,
        });
        let results = [];
        if (response.data.type === "DATA") {
          results = this.formatResult(response.data.data.list)
          if(is_export) {
            this.export_results = results
            this.exportResults()
            this.is_exporting = false
          } else {
            this.display_results = results
            this.total_items = response.data.data.total
          }
        }
      }, 600)()
    },
    formatResult(results) {
      return results.map((item, index) => {
        return {
          day: this.$options.filters.local_date(item.createdAt),
          email: item.user_email,
          arena_learn: item.acts.arena_learn,
          arena_challenge: item.acts.arena_challenge,
          vocab_topic: item.acts.vocab_topic,
          pronun_topic: item.acts.pronun_topic,
          listening_topic: item.acts.listening_topic,
          total: item.total,
        }
      })
    },
    async exportResults() {
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.json_to_sheet(this.export_results, { origin: 'A2', skipHeader: true });
      const header = [['Day', 'Email', 'Speaking Arena - Vào học', 'Speaking Arena - Thách đấu', 'Vocab Arena', 'Pronun', 'Listening', 'Total Time (minutes)'],];
      XLSX.utils.sheet_add_aoa(ws, header);
      XLSX.utils.book_append_sheet(wb, ws, 'Data');
      XLSX.writeFile(wb, `employee_report.xlsx`);
      this.export_results = [];
    },
    async recalculateReports() {
      this.is_recalculating = true
      await service.recalculateRecords({});
      this.getResults()
      this.is_recalculating = false
    },
  },
};
</script>
